import React from 'react';
import Layout from '../components/layout';
import BookDemo from '../components/BookDemo';
import ContactDetails from '../components/ContactDetails';
import { SEO } from '../components/SEO/SEO';

const ContactPage = ({ location }) => (
  <Layout>
    <SEO location={location} pageMetadata={{ title: 'Contact' }} />
    <ContactDetails />
    <BookDemo />
  </Layout>
);

export default ContactPage;
