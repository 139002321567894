import React from 'react';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';

export default function Example() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
          <div>
            <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Laat je horen</h2>
            <div className="mt-3">
              <p className="text-lg mr-12 text-gray-500">
                Heb je vragen of opmerkingen over het gebruik van Homigo? Twijfel dan niet om
                contact op te nemen.
              </p>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 md:mt-0">
            <div className="mt-9">
              <div className="flex">
                <div className="flex-shrink-0">
                  <PhoneIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <p>020 - 30 86 314</p>
                  <p>Blancefloorstraat 19-1,</p>
                  <p className="mt-1">1055 TD, Amsterdam</p>
                </div>
              </div>
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <MailIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <p>home@homigo.nl</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
